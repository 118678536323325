import { getStaticImageSrc } from 'utils/getImageSrc';

import { OAuthButton } from './OAuthButton';

type Props = {
  getUrl: () => string | Promise<string>;
};

export function AppleOAuthButton({ getUrl }: Props) {
  return (
    <OAuthButton
      imgSrc={getStaticImageSrc('icons/social/apple.svg')}
      imgAlt="Apple"
      text={getText('Continue with Apple')}
      getUrl={getUrl}
      qaId="apple-oauth"
    />
  );
}
