import { css } from 'styled-components';

import { colors, cssBreakpoints, textStyles } from 'theme/theme';

export function getStyle(style: 'default' | 'modal' | 'form' | undefined) {
  if (style === 'modal') {
    return css`
      padding: 16px;

      @media all and (min-width: ${cssBreakpoints.mdUp}) {
        padding: 25px;
      }
    `;
  }

  if (style === 'form') {
    return css`
      padding: 12px;
      color: ${colors.lightContentGrey};

      @media all and (min-width: ${cssBreakpoints.mdUp}) {
        padding: 20px;
      }

      ${textStyles.bodySmall}
      line-height: 1.375;
    `;
  }

  return css`
    padding: 16px 24px;

    @media all and (min-width: ${cssBreakpoints.mdUp}) {
      padding: 24px 32px;
    }
  `;
}

export function getBorder(type: ('info' | 'warning' | 'danger') | undefined) {
  if (!type) return null;

  let color = colors.brandBlue;

  if (type === 'warning') {
    color = colors.brandYellow;
  } else if (type === 'danger') {
    color = colors.alertRed;
  }

  return css`
    border-left: solid 4px ${color};
  `;
}
