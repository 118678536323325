import { deleteCookie } from 'utils/cookies';
import { deferAndStartRouteLoadingAnimation } from 'utils/ui/routeLoadingAnimation';

import { OAuthButtonOrLinkStyled } from './OAuthButton.styled';

type Props = {
  imgSrc: string;
  imgAlt: string;
  text: string;
  getUrl: () => string | Promise<string>;
  qaId: string;
};

export function OAuthButton({ imgSrc, imgAlt, text, getUrl, qaId }: Props) {
  const click = () => {
    deferAndStartRouteLoadingAnimation(async () => {
      deleteCookie('userDismissedNotificationPreferencesDialog');
      const url = await getUrl();
      window.location.href = url;
    });
  };

  return (
    <OAuthButtonOrLinkStyled type="button" onClick={click} data-qa-id={qaId}>
      <img src={imgSrc} width={24} height={24} alt={imgAlt} />
      {text}
    </OAuthButtonOrLinkStyled>
  );
}
