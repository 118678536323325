import { Color } from 'theme/theme';

import { LoadingDotsSize } from './LoadingDotsSize';
import { LoadingDotsDot, LoadingDotsHolder } from './Spinner.styled';

type Props = {
  size?: LoadingDotsSize;
  label?: string;
  color?: Color | 'default';
};

export function LoadingDots({
  size = 'default',
  label = 'Loading',
  color = 'default',
}: Props) {
  return (
    <LoadingDotsHolder $size={size} $color={color} aria-label={label}>
      <LoadingDotsDot />
    </LoadingDotsHolder>
  );
}
