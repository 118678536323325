import { HttpError } from 'utils/HttpError';
import { log } from 'utils/logging';
import { trackError } from 'utils/trackError';

import { FormErrorData, FormErrorResponse } from './FormContext';

export function getFormErrorArray<TKey>(
  error: FormErrorResponse<TKey>,
): FormErrorData<TKey | 'general' | ''>[] {
  const responseJson = 'responseJson' in error ? error.responseJson : null;

  // log unexpected errors
  if (!responseJson) {
    if (error instanceof HttpError) {
      // only log http errors such as 504 to console
      log.error(error);
    } else {
      // but log others to trackJS
      trackError(error as Error);
    }
  }

  if (responseJson && Array.isArray(responseJson.errors))
    return responseJson.errors;

  return [
    {
      location: 'general',
      name: 'general',
      description: getText('An error has occurred. Please try again later.'),
    },
  ];
}
