import { ComponentProps, MouseEventHandler, ReactNode } from 'react';

import { Button } from 'components/Button/Button';
import { ButtonVariant } from 'components/Button/Button.types';
import { LoadingDots } from 'components/LoadingDots/LoadingDots';

import {
  SubmitButtonChildrenHolder,
  SubmitButtonLoadingDotsHolder,
} from './SubmitBaseButton.styled';

type Props = {
  children?: ReactNode;
  qaId?: string;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  submitting?: boolean;
  submitDisabled?: boolean;
  size?: 'small' | 'medium' | 'large';
  tabIndex?: number;
  type?: 'submit' | 'button';
  variant?: ButtonVariant;
  icon?: ComponentProps<typeof Button>['icon'];
  iconPosition?: ComponentProps<typeof Button>['iconPosition'];
};

export function SubmitBaseButton({
  children,
  qaId,
  disabled,
  onClick,
  submitting,
  submitDisabled,
  size,
  type,
  variant,
  ...props
}: Props) {
  return (
    <Button
      type={type || 'submit'}
      data-qa-id={qaId}
      onClick={onClick}
      disabled={submitting || disabled || submitDisabled}
      size={size}
      data-qa-submitting={submitting === true}
      variant={variant}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <div style={{ position: 'relative' }}>
        <SubmitButtonChildrenHolder>{children}</SubmitButtonChildrenHolder>

        <SubmitButtonLoadingDotsHolder>
          <LoadingDots
            size={size === 'large' ? 'small' : 'micro'}
            label="Submitting"
          />
        </SubmitButtonLoadingDotsHolder>
      </div>
    </Button>
  );
}
