import { getStaticImageSrc } from 'utils/getImageSrc';

import { OAuthButton } from './OAuthButton';

type Props = {
  getUrl: () => string | Promise<string>;
};

export function FacebookOAuthButton({ getUrl }: Props) {
  return (
    <OAuthButton
      imgSrc={getStaticImageSrc('icons/social/facebook-new.svg')}
      imgAlt="Facebook"
      text={getText('Continue with Facebook')}
      getUrl={getUrl}
      qaId="facebook-oauth"
    />
  );
}
