import styled, { keyframes } from 'styled-components';

import { Color, colors } from 'theme/theme';

import { LoadingDotsSize } from './LoadingDotsSize';

const SIZES: Record<LoadingDotsSize, number> = {
  text: 3,
  micro: 4,
  small: 6,
  default: 10,
  '2x': 20,
  '3x': 30,
};

const DURATION = 0.5;

const aFloat = keyframes`
  0% {
    transform: translateY(-75%);
  }
  100% {
    transform: translateY(75%);
  }
`;

export const LoadingDotsDot = styled.div`
  /* dot */
`;

export const LoadingDotsHolder = styled.div<{
  $size: LoadingDotsSize;
  $color: Color | 'default';
}>`
  position: relative;
  ${(props) => `
    width: ${SIZES[props.$size]}px;
    height: ${SIZES[props.$size]}px;
    color: ${
      props.color === 'default'
        ? 'currentColor'
        : colors[props.$color as keyof typeof colors]
    };
  `}
  &::before,
  &::after,
  ${LoadingDotsDot} {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: currentcolor;
    animation: ${aFloat} ${DURATION}s ease-in infinite alternate;
    will-change: animation;
  }

  &::before,
  &::after {
    content: '';
  }

  &::before {
    right: 200%;
    animation-delay: ${-(DURATION / 5)}s;
  }
  ${LoadingDotsDot} {
    left: 0;
    animation-delay: ${-(DURATION / 3 + DURATION / 5)}s;
  }

  &::after {
    left: 200%;
    animation-delay: ${-((DURATION * 2) / 3 + DURATION / 5)}s;
  }
`;
