import styled from 'styled-components';

import { ButtonOrLink } from 'components/ButtonOrLink/ButtonOrLink';
import { colors, transition } from 'theme/theme';

export const OAuthButtonOrLinkStyled = styled(ButtonOrLink)`
  transition: ${transition};
  border-radius: 8px;
  border: 1px solid ${colors.mediumContentGrey};
  color: ${colors.mediumContentGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  letter-spacing: 0.5px;
  text-decoration: none;

  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  padding-top: 10px;
  padding-bottom: 10px;

  &:hover {
    background-color: ${colors.inputGrey};
  }
`;
