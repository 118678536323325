import { sprintf } from 'sprintf-js';

import { Box } from 'components/Box/Box';
import { Text } from 'components/Text/Text';
import { colors } from 'theme/theme';

export function CharacterCounter({
  maxLength,
  currentCount,
}: {
  maxLength: number;
  currentCount: number;
}) {
  const charsLeft = maxLength - currentCount;
  return (
    <Box mt="5px" mb="20px" display="flex" justifyContent="flex-end">
      <Text
        styleAs="bodySmall"
        color={charsLeft === 0 ? colors.alertRed : colors.mediumContentGrey}
      >
        {sprintf(
          charsLeft === 1
            ? getText('%(count)s character left')
            : getText('%(count)s characters left'),
          {
            count: charsLeft,
          },
        )}
      </Text>
    </Box>
  );
}
