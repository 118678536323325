import styled from 'styled-components';

import { colors } from 'theme/theme';

import { getBorder, getStyle } from './infoBoxStyleHelpers';

export const InfoBox = styled.div<{
  $boxStyle?: 'default' | 'modal' | 'form';
  $small?: boolean;
  $type?: 'info' | 'warning' | 'danger';
  $marginTop?: number;
  $marginBottom?: number;
}>`
  border-radius: 4px;
  margin-top: ${(props) => props.$marginTop || 0}px;
  margin-bottom: ${(props) => props.$marginBottom || 0}px;
  background-color: ${(props) =>
    props.$small ? colors.inputGrey : colors.calloutGrey};

  ${(props) =>
    props.$small ? `padding: 4px 12px;` : getStyle(props.$boxStyle)};

  ${(props) => props.$type && getBorder(props.$type)};
  a {
    display: inline;
    color: ${colors.brandBlue};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;
