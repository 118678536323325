import { getStaticImageSrc } from 'utils/getImageSrc';

import { OAuthButton } from './OAuthButton';

type Props = {
  getUrl: () => string | Promise<string>;
};

export function GoogleOAuthButton({ getUrl }: Props) {
  return (
    <OAuthButton
      imgSrc={getStaticImageSrc('icons/social/google.svg')}
      imgAlt="Google"
      text={getText('Continue with Google')}
      getUrl={getUrl}
      qaId="google-oauth"
    />
  );
}
