/*
  `zip` in the style of Python
  zip([1, 2, 3], [4, 5, 6]) => [[1, 4], [2, 5], [3, 6]]
*/
export function zip<S, T>(
  a: ReadonlyArray<S>,
  b: ReadonlyArray<T>,
): Array<[S, T]> {
  return a.map((x, i) => [x, b[i]]);
}
